export const routes = {
  /**
   * Updated Routes
   */
  HOME: '/',
  SEARCH: '/search',
  MODELS: '/models',

  /**
   * Legacy Routes
   */
  DASHBOARD: '/dashboard',
  TAG_VIEWER: '/src/:id',
  DOC_VIEWER: '/document/:id',
  TEXT_FUNDAMENTAL: '/text-fundamental',
  INQUIRE_HERE_POPUP: '/inquire-here',
  SAMPLE: '/sample',
  LIVE_PREVIEW: '/preview/:companyId',

  /**
   * Auth Routes
   */
  LOGIN: '/login',
  REGISTRATION: '/register',
  SIGN_UP: '/sign-up',
  VERIFY_EMAIL: '/verify-email',
  REGISTER_THANKYOU_POPUP: '/register-thank-you',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password',

  /**
   * Generic popups
   */
  REQUEST_A_MODEL: '?popup=request-a-model',
  ADD_NEW_MODEL: '?popup=add-new-model',
  API_SETTINGS: '?popup=api-settings',
  EXCEL_PLUGIN: '?popup=excel-plugin',
  DOWNLOAD_MODEL: '?popup=download-model',
  UNSUBSCRIBE_MODEL: '?popup=unsubscribe-model',
  ROLLUP_NUMBERS: '/rollup',

  /**
   * Error Pages
   */
  ERROR_404: '/404',
  ERROR_403: '/403',
  ERROR_401: '/401',
  ERROR_500: '/500'
};
