import React, { lazy } from 'react';
import { USE_SSO_LOGIN } from 'core/modules/auth.utils';
import { routes } from '../routes';
import { RouteObject } from 'react-router';

const LoginPage = lazy(async () => import('../../../pages/Login'));
const SignUpPage = lazy(async () => import('../../../pages/SignUp'));
const RegisterPage = lazy(async () => import('../../../pages/Register'));
const ThankyouVerifyEmailPage = lazy(async () => import('../../../pages/ThankyouVerifyEmail'));
const ForgetPasswordPage = lazy(async () => import('../../../pages/ForgetPassword'));
const ResetPasswordPage = lazy(async () => import('../../../pages/ResetPassword'));

export const authRoutes: RouteObject[] = USE_SSO_LOGIN
  ? []
  : [
      {
        path: routes.SIGN_UP,
        element: <SignUpPage />
      },
      {
        path: routes.LOGIN,
        element: <LoginPage />
      },
      {
        path: routes.REGISTRATION,
        element: <RegisterPage />
      },
      {
        path: routes.VERIFY_EMAIL,
        element: <ThankyouVerifyEmailPage />
      },
      {
        path: routes.FORGET_PASSWORD,
        element: <ForgetPasswordPage />
      },
      {
        path: routes.RESET_PASSWORD,
        element: <ResetPasswordPage />
      }
    ];
