import React, { useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LeftArrowIcon, SearchIcon } from 'components/Icons';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { routes } from 'main/router';
import { Container, Input, InputWrapper } from './SearchInput.styles';
import { Mixpanel } from 'mixPanel';

export const SearchInput = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [query, setQuery] = useState(decodeURIComponent(params.get('q') ?? ''));

  const isSearchPage = location.pathname.includes(routes.SEARCH);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.trimStart());
  };

  const handleSearch = () => {
    if (!query.trim()) return;

    const from = !isSearchPage ? location.pathname : undefined;

    Mixpanel.track('marketplace:search', { query });

    navigate(
      { pathname: routes.SEARCH, search: `?q=${encodeURIComponent(query.trim())}` },
      { state: { from } }
    );
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleBack = () => {
    setQuery('');
  };

  return (
    <Container>
      {isSearchPage && (
        <Link to={location.state?.from ?? routes.HOME} onClick={handleBack}>
          <LeftArrowIcon />
        </Link>
      )}
      <InputWrapper onKeyUp={handleKeyUp}>
        <Input
          type="text"
          placeholder="Search data sheets by ticker or company name"
          value={query}
          onChange={handleChange}
          maxLength={100}
        />
        <Tooltip title="Search">
          <SearchIcon onClick={handleSearch} />
        </Tooltip>
      </InputWrapper>
    </Container>
  );
};
