import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SET_LANGUAGE } from './localization/reducer';
import Header from './components/Header';
import Router from './components/Router';
import Popup from 'components/Popup';
import InitializeIntercom from 'intercom';
import { isLoggedIn } from 'core/modules/auth.utils';
import { actions as userDetailAction } from 'userDetail';
import { queryClient } from 'core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LicenseInfo } from '@mui/x-license-pro';
import { MenuV2 } from 'components/MenuV2';
import { DaloopaTheme } from 'ui-kit';
import { themeV2 } from 'main/config/theme';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Router as RouterV2 } from 'main/router/router';
import { generalState } from 'store/features';
import { useAppSelector } from 'store/hooks';
import { Container } from 'App.styles';
import { ToastContextProvider } from 'presentation/contexts';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { init } from 'commandbar';
import { useStatsigClient } from '@statsig/react-bindings';

init('3b514a4d');

const DATA_GRID_LICENSE = process.env.REACT_APP_DATA_GRID_LICENSE;
if (DATA_GRID_LICENSE) {
  LicenseInfo.setLicenseKey(DATA_GRID_LICENSE);
}
export const App = () => {
  const dispatch = useDispatch();
  const {
    featureFlags: { isMarketplaceV2Enabled },
    menuExpanded
  } = useAppSelector(generalState);

  const { client } = useStatsigClient();
  const enableGuidedTour = client.checkGate('enable-guided-tour-commandai');

  const theme = isMarketplaceV2Enabled ? themeV2 : DaloopaTheme;

  const { isAuthenticated, tokenData } = isLoggedIn();

  useEffect(() => {
    dispatch({
      type: SET_LANGUAGE,
      lang: 'en'
    });
    if (isAuthenticated) {
      dispatch(userDetailAction.getUserDetails());
    }
    if (process.env.REACT_APP_ENABLE_INTERCOM === 'true' && isAuthenticated) {
      InitializeIntercom();
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (enableGuidedTour && isAuthenticated) {
      void window.CommandBar.boot(tokenData?.email);
    } else {
      window.CommandBar.shutdown(); // Remove if FF was previously enabled during user session
    }
    return () => {
      window.CommandBar.shutdown();
    };
  }, [isAuthenticated, enableGuidedTour, tokenData?.email]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ToastContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <CssBaseline />
          {!isMarketplaceV2Enabled && (
            <>
              <Header />
              <Router />
            </>
          )}
          {isMarketplaceV2Enabled && (
            <>
              <MenuV2 />
              <Container menuExpanded={menuExpanded}>
                <SearchInput />
                <RouterV2 />
              </Container>
            </>
          )}
          <Popup />
        </ToastContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
