import { SSORedirect } from 'core';
import { isLoggedIn, USE_SSO_LOGIN } from 'core/modules/auth.utils';
import React from 'react';
import { Navigate, Route } from 'react-router';
import { routes } from './routes';
import { useDispatch } from 'react-redux';
import { setFeatureFlags } from 'store';

type Props = {
  children: React.ReactNode;
  redirectToV1?: boolean;
};

export const ProtectedRoute = ({ children, redirectToV1 }: Props) => {
  const dispatch = useDispatch();
  const isAuthenticated = isLoggedIn().isAuthenticated;

  const path = window.location.pathname;
  const from = path.concat(window.location.search);

  if (redirectToV1) {
    dispatch(setFeatureFlags({ isMarketplaceV2Enabled: false, markeplaceV2Validated: true }));
  }

  if (!isAuthenticated) {
    if (USE_SSO_LOGIN) {
      return <SSORedirect />;
    }

    return <Navigate to={routes.LOGIN} state={{ from }} />;
  }

  return <>{children}</>;
};

type GetProtectedRouteProps = {
  path: string;
  element: JSX.Element;
};

export const getProtectedRoute = ({ element, path }: GetProtectedRouteProps) => {
  const isAuthenticated = isLoggedIn().isAuthenticated;

  const from = window.location.pathname.concat(window.location.search);

  if (!isAuthenticated) {
    if (USE_SSO_LOGIN) {
      return <SSORedirect />;
    }

    return <Route element={<Navigate to={routes.LOGIN} state={{ from }} />} path={path} />;
  }

  return <Route element={element} path={path} />;
};
