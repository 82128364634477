import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import withWidth from '@material-ui/core/withWidth';
import { Waypoint } from 'react-waypoint';

import useLocal from 'localization';

import { actions as userDetailAction } from 'userDetail';
import { isLoggedIn, USE_SSO_LOGIN } from 'core/modules/auth.utils';
import { actions as loginActions } from 'pages/Login/duck';
import routes from 'components/Router/routes';

import SubHeader from 'components/SubHeader';
import ModelHeaderActions from 'components/ModelHeaderActions';
import ModelSidebar from 'components/ModelSidebar';
import ModelList from 'components/ModelList';
import ModelPageRqModel from 'components/ModelPageRqModel';
import UnSubscribeModelPopup from 'components/UnSubscribeModelPopup';
import ModelListSkeleton from 'components/ModelListSkeleton';
import ModelSearch from 'components/ModelSearch';
import NoModelFound from 'components/NoModelFound';
import MyModelList from 'components/MyModelList';
import Mixpanel from '../../mixPanel';

import { allModelId, industryModelsId, myModelId } from 'components/ModelSidebar/types';

import { ModelsTypes, actions, modelsSelectors } from './duck';
import { SHOW_REQUEST_MODEL } from '@constants';
import useStyles from './style';
import FreeUser from 'components/FreeUser';
import { UserDetailSelectors } from 'userDetail';
import IndustryModels from '../IndustryModels/IndustryModels';
import industryModelsSelector from '../IndustryModels/duck/selector';
import { useNavigate } from 'react-router';

const Models: React.FC<ModelsTypes.ModelsProps> = ({ width }: ModelsTypes.ModelsProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [showList, setShowList] = useState(false);
  // const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [showUnSubscribePopup, setShowUnSubscribePopup] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [showMyModel, setShowMyModel] = useState(false);
  const [isClearSearch, setClearSearch] = useState(false);
  const [showIndustryModels, setShowIndustryModels] = useState(false);

  const subscribeModelConfirmMsg = useLocal('subscribe_model_confirm');
  const modelTitle = useLocal('model');
  const searchResult = useLocal('search_result');
  const itemFound = useLocal('item_found');
  const noItemFound = useLocal('no_item_found');

  const dispatch = useDispatch();
  const numberOfIndustriesByDefault = width === 'sm' || width === 'xs' ? 4 : 6;
  const numberOfModelPerIndustryByDefault = width === 'sm' || width === 'xs' ? 4 : 6;
  /**
   * Sectors
   */
  const sectorsList = useSelector(modelsSelectors.getSectorsList);
  const totalNumberOfModels = useSelector(modelsSelectors.getTotalNumberOfModels);
  const hasSectorError = useSelector(modelsSelectors.hasSectorError);
  const isSectorLoading = useSelector(modelsSelectors.isSectorLoading);
  const sectorError = useSelector(modelsSelectors.sectorError);
  const selectedSector = useSelector(modelsSelectors.getSelectedSector);
  const isUpdating = useSelector(modelsSelectors.isUpdating);
  const isIndustryModelLoading = useSelector(modelsSelectors.isIndustryModelLoading);
  const totalNumberOfIndustries = useSelector(modelsSelectors.getTotalNumberOfIndustries);
  const subscriptionPendingModel = useSelector(modelsSelectors.getSubscriptionPendingModel);
  const { allowedSectors, maxSubscriptions, subscribedCount } = useSelector(
    UserDetailSelectors.getUserDetails
  );

  const myModels = useSelector(modelsSelectors.getMyModels);

  /**
   * Models
   */

  const modelsList = useSelector(modelsSelectors.getModelsList);
  const modelsListNotDisabled = (modelsList
    ?.map((model) => model?.clientModels?.filter((client) => !client?.company?.disabled))
    .filter((item) => item?.length > 0) as unknown) as ModelsTypes.modelObject[];
  const industryModels = useSelector(industryModelsSelector.getIndustryModels);
  const searchModelResultsLength = industryModels?.length + modelsListNotDisabled?.length;
  const hasModelsError = useSelector(modelsSelectors.hasModelsError);
  const isModelsLoading = useSelector(modelsSelectors.isModelsLoading);
  const modelsError = useSelector(modelsSelectors.modelsError);
  const unsubscribed = useSelector(modelsSelectors.unsubscribed);

  const isFreeTier = useSelector(UserDetailSelectors.isFreeTier);
  const isTrialTier = useSelector(UserDetailSelectors.isTrialTier);
  const isEnterpriseTier = useSelector(UserDetailSelectors.isEnterpriseTier);

  const showLoading = isModelsLoading;

  const allowedIndustriesFromSector = (sectorsList
    ?.find((sector) => sector.id === selectedSector && allowedSectors?.includes(selectedSector))
    ?.industriesSet.map((industry) => industry?.id) as unknown) as [string];

  useEffect(() => {
    dispatch(actions.getSectors());
    Mixpanel.track('marketplace:models:view');
    const params: ModelsTypes.getModelParam = {
      numberOfIndustries: numberOfIndustriesByDefault,
      numberOfModelsPerIndustry: numberOfModelPerIndustryByDefault
    };
    dispatch(actions.getModels(params));
  }, [dispatch, numberOfIndustriesByDefault, numberOfModelPerIndustryByDefault, unsubscribed]);

  useEffect(() => {
    if (isLoggedIn().isAuthenticated) {
      dispatch(userDetailAction.getUserDetails());
    }
  }, []);

  const handleCloseUnSubscribePopup = () => {
    setShowUnSubscribePopup(false);
  };
  const handleUnSubscribeModel = (/* modelId: number | string */) => {
    handleCloseUnSubscribePopup();
  };
  // const handleClosePopup = () => {
  //   setShowSubscribePopup(false);
  // };
  const handleSubscribeModel = (
    companyId: string,
    modelId: string,
    modelIndex: number,
    industryIndex: number,
    ticker: string
  ) => {
    if (!USE_SSO_LOGIN && !isLoggedIn().isAuthenticated) {
      dispatch(
        loginActions.addActionQueue(
          actions.activateAndDownloadModel(companyId, modelId, modelIndex, industryIndex, ticker)
        )
      );
      navigate(routes.LOGIN);
      return;
    } else {
      const activate = confirm(subscribeModelConfirmMsg);
      if (activate) {
        dispatch(
          actions.activateAndDownloadModel(companyId, modelId, modelIndex, industryIndex, ticker)
        );
      }
    }
  };

  const handleScroll = () => {
    if (!isUpdating) {
      const params: ModelsTypes.getModelParam = {
        numberOfIndustries: numberOfIndustriesByDefault,
        numberOfModelsPerIndustry: numberOfModelPerIndustryByDefault,
        isUpdate: true,
        pageNumber
      };
      if (selectedSector !== allModelId) {
        params.sectorId = parseInt(selectedSector);
      }
      if (keyword !== '' && keyword) {
        params.keyword = keyword;
      }
      dispatch(actions.getModels(params));
      setPageNumber((pageNumber) => pageNumber + 1);
    }
  };

  const handelSearch = (keyword: string) => {
    setKeyword(keyword);
    setShowMyModel(false);
    setPageNumber(1);
    dispatch(actions.setSelectedSectors(allModelId));
    setShowIndustryModels(false);
    const params: ModelsTypes.getModelParam = {
      numberOfIndustries: numberOfIndustriesByDefault,
      numberOfModelsPerIndustry: numberOfModelPerIndustryByDefault
    };
    if (keyword !== '') {
      params.keyword = keyword;
    }

    dispatch(actions.getModels(params));
  };

  const handelSectorChange = (sectorId: string) => {
    setKeyword('');
    setClearSearch(true);
    setPageNumber(1);
    dispatch(actions.setSelectedSectors(sectorId));
    if (sectorId === myModelId) {
      Mixpanel.track('marketplace:my_models_view');
      setShowMyModel(true);
      setShowIndustryModels(false);
      dispatch(actions.getMyModels());
    } else if (sectorId === industryModelsId) {
      setShowMyModel(false);
      setShowIndustryModels(true);
      Mixpanel.track('marketplace:industry_models');
    } else {
      setShowIndustryModels(false);
      setShowMyModel(false);
      const params: ModelsTypes.getModelParam = {
        numberOfIndustries: numberOfIndustriesByDefault,
        numberOfModelsPerIndustry: numberOfModelPerIndustryByDefault
      };
      if (![allModelId, industryModelsId].includes(sectorId)) {
        params.sectorId = parseInt(sectorId);
      }
      dispatch(actions.getModels(params));
    }
  };

  return (
    <Box pt={{ xs: 19, sm: 17 }}>
      <SubHeader title={`${modelTitle} ${totalNumberOfModels ? `(${totalNumberOfModels})` : ''}`}>
        <ModelSearch
          onSearchModel={handelSearch}
          clearSearch={isClearSearch}
          setClearSearch={setClearSearch}
          deleyTime={500}
          throttle
        />
        {!showIndustryModels && (
          <ModelHeaderActions setShowList={setShowList} showList={showList} />
        )}
      </SubHeader>

      <Container className={classes.container} maxWidth="xl" data-testid="page-container">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} md={2} lg={2} key="sidebar-box">
            <ModelSidebar
              hasSectorError={hasSectorError}
              sectorError={sectorError}
              isSectorLoading={isSectorLoading}
              sectorList={sectorsList}
              selectFilter={handelSectorChange}
              selectedSector={selectedSector}
              isFreeTier={isFreeTier}
              allowedSectors={allowedSectors}
              isEnterpriseTier={isEnterpriseTier}
              isTrialTier={isTrialTier}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={10} lg={10} key="content-box" id={'content-box'}>
            {isFreeTier && (
              <FreeUser
                availableModelsNum={maxSubscriptions - subscribedCount}
                usedModelsNum={subscribedCount}
              ></FreeUser>
            )}
            {hasModelsError && <Alert severity="error">{modelsError}</Alert>}
            {keyword.length > 0 &&
              (showLoading ? (
                <Box pb={2}>
                  <h2>
                    <Box fontWeight="lighter">
                      <Skeleton variant="rect" height={50} />
                    </Box>
                  </h2>
                </Box>
              ) : (
                <Box pb={2}>
                  <Box fontSize={24} fontWeight="lighter">
                    <strong>{searchResult}</strong> -{' '}
                    {searchModelResultsLength ? (
                      <>
                        {searchModelResultsLength} {itemFound}
                      </>
                    ) : (
                      <>{noItemFound}</>
                    )}
                  </Box>
                </Box>
              ))}
            {showMyModel && (
              <MyModelList showList={showList} isLoading={showLoading} myModel={myModels} />
            )}
            {showIndustryModels && <IndustryModels query={keyword} />}
            {!showMyModel && !showIndustryModels && (
              <>
                {showLoading ? (
                  <ModelListSkeleton
                    numberOfIndustry={numberOfIndustriesByDefault}
                    numberOfModel={numberOfModelPerIndustryByDefault}
                    showList={showList}
                  />
                ) : modelsList?.length &&
                  modelsListNotDisabled?.length &&
                  modelsList?.length >= modelsListNotDisabled?.length ? (
                  <>
                    {modelsList.map((item, index) => (
                      <>
                        <ModelList
                          onSubscribeModel={(companyId, modelId, modelIndex, ticker) =>
                            handleSubscribeModel(companyId, modelId, modelIndex, index, ticker)
                          }
                          onUnSubscribeModel={() => setShowUnSubscribePopup(true)}
                          key={`${item?.industry?.id}-${index}-${item.clientModels.length}`}
                          {...item}
                          showList={showList}
                          numberOfIndustriesByDefault={numberOfIndustriesByDefault}
                          loadMore={(industryId) =>
                            dispatch(actions.getModelsFromIndustry(industryId, index))
                          }
                          isIndustryModelLoading={isIndustryModelLoading}
                          subscriptionPendingModel={subscriptionPendingModel}
                          isFreeTier={isFreeTier}
                          isTrialTier={isTrialTier}
                          allowedIndustries={allowedIndustriesFromSector}
                          maxSubscriptions={maxSubscriptions}
                          subscribedCount={subscribedCount}
                        />
                        {Math.floor(modelsList.length - 2) === index &&
                          totalNumberOfIndustries > modelsList.length && (
                            <Waypoint onEnter={handleScroll} />
                          )}
                      </>
                    ))}
                    {isUpdating && (
                      <ModelListSkeleton
                        numberOfIndustry={numberOfIndustriesByDefault}
                        numberOfModel={numberOfModelPerIndustryByDefault}
                        showList={showList}
                      />
                    )}
                    <IndustryModels query={keyword} />
                  </>
                ) : (
                  <NoModelFound />
                )}
              </>
            )}
            {SHOW_REQUEST_MODEL && <ModelPageRqModel />}
          </Grid>
        </Grid>
      </Container>
      {/* {showSubscribePopup && (
        <SubscribeModelPopup
          onClosePopup={handleClosePopup}
          onSubscribeModel={handleSubscribeModel}
          {...subscribeModelData}
        />
      )} */}
      {showUnSubscribePopup && (
        <UnSubscribeModelPopup
          onClose={handleCloseUnSubscribePopup}
          modelId={2}
          modelName="CDLD - Cardlytics, Inc."
          onUnSubscribeModel={handleUnSubscribeModel}
        />
      )}
    </Box>
  );
};

export default withWidth()(Models);
