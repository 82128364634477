import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import Container from '@material-ui/core/Container';

import Menu from 'components/Menu';
import routes from 'components/Router/routes';
import { ReactComponent as Logo } from 'assets/logo.svg';

import useStyles from './style';
import { actions, modelsSelectors } from '../../pages/Models/duck';
import { useDispatch, useSelector } from 'react-redux';
import { useSubscription } from '@apollo/client';
import { updateNotification } from '../../pages/Models/duck/types';
import { UPDATE_NOTIFICATION_SUBSCRIPTION } from '../../pages/Models/constants';
import { isLoggedIn } from 'core/modules/auth.utils';
import { GraphQL, getUserInfo } from 'core';
import loginSelectors from '../../pages/Login/duck/selector';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@material-ui/icons';

const PlusLogo = styled.p`
  font-family: 'Comfortaa', sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  margin: 0;
  margin-left: 5px;
  font-size: 27px;
  color: black;
`;

const PlusLabel = styled.p`
  font-family: 'Comfortaa', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  width: max-content;
  margin: 0;
  margin-left: 5px;
  margin-top: 6px;
  font-size: 14px;
  color: #ddd;
`;

const Header = () => {
  const classes = useStyles();
  const isUserLoggedIn = useSelector(loginSelectors.isUserLoggedIn);

  const isExcelPluginSrc =
    window.location.search?.includes('?excel_plugin=true') &&
    window.location.pathname?.includes('/src/');
  const isEarningNotificationsEnabled = useSelector(modelsSelectors.isEarningNotificationsEnabled);
  const NOTIFICATIONS_POLLING = process.env.REACT_APP_NOTIFICATIONS_POLLING === 'true';
  const dispatch = useDispatch();
  const location = useLocation();
  const info = getUserInfo();

  useEffect(() => {
    if (
      isLoggedIn().isAuthenticated &&
      isEarningNotificationsEnabled &&
      NOTIFICATIONS_POLLING &&
      !location.pathname.includes('/src/')
    ) {
      dispatch(actions.getUpdateNotifications());
    } else {
      dispatch(actions.stopPolling());
    }
  }, [isEarningNotificationsEnabled, isLoggedIn, NOTIFICATIONS_POLLING, isUserLoggedIn, location]);

  useEffect(() => {
    if (isLoggedIn().isAuthenticated) {
      dispatch(actions.getEarningUpdateNotificationConfig());
    }
  }, [isUserLoggedIn]);

  useSubscription<{
    earningUpdateNotifications: updateNotification[];
  }>(UPDATE_NOTIFICATION_SUBSCRIPTION, {
    skip:
      !isUserLoggedIn ||
      !isLoggedIn().isAuthenticated ||
      !isEarningNotificationsEnabled ||
      NOTIFICATIONS_POLLING,
    shouldResubscribe: false,
    client: GraphQL,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data && data.earningUpdateNotifications) {
        dispatch(actions.getUpdateNotificationSuccess(data.earningUpdateNotifications));
      }
    }
  });

  return (
    <>
      {!isExcelPluginSrc && (
        <>
          <Box position="fixed" width="100vw" zIndex={9} top={0}>
            <AppBar position="static">
              <Container maxWidth="xl">
                <Box display="flex">
                  <Box display="flex" flexGrow={1}>
                    <Link className={classes.logoLink} to={routes.HOME}>
                      <SvgIcon className={classes.logo} viewBox="" component={Logo} />
                      {(info?.productType === 'daloopa_plus' ||
                        info?.productType === 'daloopa_plus_time_ltd') && <PlusLogo>plus</PlusLogo>}
                      {info?.productType === 'daloopa_plus_time_ltd' && (
                        <>
                          <PlusLabel>limited time upgrade</PlusLabel>
                          <Tooltip title="To learn more, please reach out to us at sales@daloopa.com">
                            <InfoOutlined style={{ fontSize: '14px', marginLeft: '3px' }} />
                          </Tooltip>
                        </>
                      )}
                    </Link>
                  </Box>
                  <Box py={0}>
                    <Menu />
                  </Box>
                </Box>
              </Container>
            </AppBar>
          </Box>
        </>
      )}
    </>
  );
};

export default React.memo(Header);
