import React from 'react';

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M18 6L6.00001 18M6 6L18 18" stroke="currentColor" />
  </svg>
);
