import { Button, styled } from '@mui/material';

export const Container = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open: boolean;
}>(({ open }) => ({
  height: '100vh',
  width: open ? '290px' : '80px',
  minWidth: open ? '290px' : '80px',
  transition: 'min-width 0.15s ease, width 0.15s ease',

  '& a': {
    textDecoration: 'none'
  },

  '& > div': {
    ...(!open && {
      '& a': {
        border: 'none'
      },

      '& .daloopa-icon': {
        display: 'none'
      },

      '& .notifications-badge': {
        position: 'absolute',
        top: '8px',
        left: '40px',
        padding: 0,
        width: '10px',
        height: '10px',
        borderRadius: '100%',
        fontSize: 0
      }
    })
  }
}));

export const ContentWrapper = styled('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1200,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: 'inherit',
  minWidth: 'inherit',
  padding: '16px',
  background: '#0D213B',
  overflowX: 'hidden'
}));

export const MenuHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '40px',

  '& > button': {
    width: '48px',
    height: '48px',
    padding: '8px'
  }
}));

export const MenuMainNavigation = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

export const MenuSubNavigation = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 'auto',
  gap: '8px'
}));

export const MenuButton = styled(Button)(() => ({
  display: 'flex',
  gap: '10px',
  width: '100%',
  minWidth: 'unset',
  height: '52px',
  alignItems: 'center',
  justifyContent: 'left',
  color: '#F2F2F2',
  padding: '12px',
  textTransform: 'none',
  fontSize: '16px',
  lineHeight: '28px',

  '& svg': {
    fontSize: '24px',
    minWidth: '24px'
  },

  '&:hover': {
    backgroundColor: '#081221'
  },
  '&:active': {
    backgroundColor: '#263B54'
  }
}));

export const OldMarketPlaceButton = styled('a')(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '8px',
  color: 'white',
  padding: '4px 10px',
  fontSize: '12px',
  textDecoration: 'none',
  textTransform: 'uppercase',
  border: '1px solid white',
  borderRadius: '4px',
  cursor: 'pointer',

  '& svg': {
    fontSize: '18px',
    minWidth: '18px',
    minHeight: '18px'
  },
  '& span': {
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },

  '&:hover': {
    backgroundColor: '#263B54'
  }
}));

export const NotificationBadge = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '21px',
  padding: '0 10px',
  height: '20px',
  marginLeft: 'auto',
  background: '#EF5350',
  fontSize: '12px',
  lineHeight: '20px',
  overflow: 'hidden'
}));
