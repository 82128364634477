import { styled } from '@mui/material';

export const Container = styled('div')<{ menuExpanded: boolean }>(({ theme, menuExpanded }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
  maxWidth: `min(${menuExpanded ? 'calc(100% - 290px)' : 'calc(100% - 80px)'}, 1302px)`,
  margin: '0 auto',
  padding: '0 16px',

  [theme.breakpoints.up('xl')]: {
    padding: '24px 62px'
  }
}));
